<!--
 * @Description: 设备管理
 * @Author: ChenXueLin
 * @Date: 2021-09-01 09:58:42
 * @LastEditTime: 2022-07-15 15:55:37
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="equipCodeList">
              <input-plus
                v-model="searchForm.equipCodeList"
                valueType="Array"
                placeholder="设备编号"
                title="设备编号"
                label="设备编号"
                clearable
              ></input-plus>
              <!-- <el-input
                v-model="searchForm.equipCode"
                placeholder="设备编号"
                title="设备编号"
              ></el-input> -->
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstClassId">
              <e6-vr-select
                v-model="searchForm.firstClassId"
                :loading="firstClassLoading"
                :data="firstClassEnum"
                placeholder="商品分类"
                title="商品分类"
                @change="firstClassChange"
                @clear="firstClassChange"
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassId">
              <e6-vr-select
                v-model="searchForm.secondClassId"
                :data="secondClassEnum"
                :loading="secondClassIdLoading"
                @change="secondClassChange"
                @clear="secondClassChange"
                placeholder="商品开票名称"
                title="商品开票名称"
                :props="{
                  id: 'secondClassId',
                  label: 'secondClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassId">
              <e6-vr-select
                v-model="searchForm.thirdClassId"
                :data="thirdClassEnum"
                :loading="thirdClassLoading"
                placeholder="商品名称"
                title="商品名称"
                :props="{
                  id: 'thirdClassId',
                  label: 'thirdClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="installType">
              <e6-vr-select
                v-model="searchForm.installType"
                :data="equipInstallTypeEnum"
                placeholder="安装类型"
                title="安装类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="fixedOrMoved">
              <e6-vr-select
                v-model="searchForm.fixedOrMoved"
                :data="fixedOrMovedEnum"
                placeholder="固定/移动"
                title="固定/移动"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="virtualType">
              <e6-vr-select
                v-model="searchForm.virtualType"
                :data="typeIdEnum"
                placeholder="商品类型"
                title="商品类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                :loading="corpListLoaing"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sevStatus">
              <e6-vr-select
                v-model="searchForm.sevStatus"
                :data="finSevStatusEnum"
                placeholder="使用状态"
                title="设备状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="simNo">
              <el-input
                v-model="searchForm.simNo"
                placeholder="SIM卡号"
                title="SIM卡号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="commids">
              <el-input
                v-model="searchForm.commids"
                placeholder="中心识别码"
                title="中心识别码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="encryptionChipCode">
              <el-input
                v-model="searchForm.encryptionChipCode"
                placeholder="加密芯片号"
                title="加密芯片号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isRepair">
              <e6-vr-select
                v-model="searchForm.isRepair"
                :data="[
                  {
                    id: true,
                    label: '是'
                  },
                  {
                    id: false,
                    label: '否'
                  }
                ]"
                placeholder="是否有维修"
                title="是否有维修"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            show-overflow-tooltip
            label="序号"
            width="60"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <!-- <template slot-scope="{ row }">
              <span
                v-if="column.fieldName === 'installGroup' && row.installGroup"
              >
                {{ row[column.fieldName].secondClassName }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template> -->
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import goodsClassInteractions from "@/mixins/commomFormInteractions/goodsClassInteractions";
import listPageReszie from "@/mixins/list-page-resize";
import { getEquipList, findDownList } from "@/api";
import { printError } from "@/utils/util";
import inputPlus from "@/components/inputPlus";
const nowTime = new Date().getTime(); // 打开页面时的时间戳

export default {
  name: "EquipList",
  components: { inputPlus },
  mixins: [base, listPage, listPageReszie, goodsClassInteractions],
  data() {
    return {
      total: 0,
      fixedOrMovedEnum: [], // 移动/固定枚举
      typeIdEnum: [], // 商品类型枚举(主机/配件)
      equipInstallTypeEnum: [], // 安装类型枚举
      finSevStatusEnum: [], // 使用状态枚举
      corpList: [], // 客户列表枚举
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        equipCodeList: [], // 设备编号集合
        equipCode: "", //设备编号
        sevStatus: "", // 使用状态
        firstClassId: "", //商品分类
        secondClassId: "", //商品开票名称
        thirdClassId: "", //商品名称
        installType: "", //安装类型id
        fixedOrMoved: "", //固定/移动
        virtualType: "", //商品类型
        corpId: "", //客户ID
        equipStatus: "", //设备状态
        terStatus: "", //终端状态
        simNo: "", //sim卡号
        commids: "", //中心识别码
        encryptionChipCode: "", //加密芯片号
        isRepair: "", //是否维修
        createdBy: "", //创建人
        createTime: [nowTime - 2592000000], //创建时间
        startCreatedTime: nowTime - 2592000000, //创建开始时间
        endCreatedTime: "" //创建结束时间
      },
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      columnData: [
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "virtualTypeName",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixedOrMovedName",
          display: true,
          fieldLabel: "固定/移动",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sevStatusName",
          display: true,
          fieldLabel: "使用状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installGroupName",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "encryptionChipCode",
          display: true,
          fieldLabel: "加密芯片号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "repairTime",
          display: true,
          fieldLabel: "维修次数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTypeName",
          display: true,
          fieldLabel: "安装类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
    this.loadCorpData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //获取设备列表
    async queryList() {
      const {
        startCreatedTime,
        corpId,
        firstClassId,
        equipCodeList
      } = this.searchForm;
      let queryFlag = startCreatedTime;
      queryFlag = queryFlag || corpId || firstClassId;
      queryFlag = queryFlag || equipCodeList.length;
      if (!queryFlag) {
        this.$message({
          type: "warning",
          duration: 4000,
          message:
            "客户名称、商品分类、设备编号、创建时间必须选择其中之一作为查询条件！"
        });
        return;
      }
      try {
        this.loading = true;
        let res = await getEquipList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [
          findDownList([
            "fixedOrMoved",
            "typeId",
            "equipInstallType",
            "finSevStatus"
          ])
        ];
        let [findDownListRes] = await Promise.all(promiseList);
        this.fixedOrMovedEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.fixedOrMoved"
        });
        this.typeIdEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.typeId"
        });
        this.equipInstallTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.equipInstallType"
        });
        this.finSevStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.finSevStatus"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.loadCorpData(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      if (val.id == 1) {
        this.routerPush({
          name: "equipSceneManage/equipDetail",
          params: {
            refresh: true,
            origin: this.$route.name,
            equipId: row.equipId
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
